body {
  background: rgb(240, 242, 245);
  background: radial-gradient(circle, rgba(240, 242, 245, 1) 20%, rgba(200, 200, 200, 1) 63%);
}

.login-wrapper {
  height: 100vh;
  position: relative;

  .login-bg,
  .login-bg-2 {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0.3;
  }

  .login-bg-2 {
    left: 0;
    bottom: 0;
  }

  .login {
    height: 100%;
    z-index: 2;
    position: relative;

    .ant-layout {
      padding: 16px;
      background-color: transparent !important;

      h3 {
        font-family: 'Almarai', sans-serif;
        font-weight: 700;
        text-align: center;
        margin-top: -30px;
        margin-bottom: 34px;
        color: #cecece;

        span {
          color: #c2e64c;
        }
      }
    }
  }
}

@primary-color: #95ba1d;