main {
  position: relative;

  div.page {
    // position: absolute;
    width: 100%;
    height: 100%;

    .page-content {
      padding: 0 24px;
    }
  }
}

.page-anim-enter {
  opacity: 0;
  transform: translateY(50%);
}
.page-anim-enter-active {
  opacity: 1;
  transform: translate(0);
  transition: opacity 300ms, transform 300ms ease-out;
}
.page-anim-exit {
  opacity: 1;
}
.page-anim-exit-active {
  opacity: 0;
  transform: translateY(50%);
  transition: opacity 200ms, transform 200ms ease-in;
}
