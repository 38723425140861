@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/almarai/v12/tssoApxBaigK_hnnS_anhng.ttf) format('truetype');
}
@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/almarai/v12/tssoApxBaigK_hnnS-aghng.ttf) format('truetype');
}
@font-face {
  font-family: 'Bree Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/breeserif/v17/4UaHrEJCrhhnVA3DgluAx60.ttf) format('truetype');
}
